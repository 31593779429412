import { defineComponent } from "vue";

export default defineComponent({

    name: 'Footer',
    setup () {
        const date = new Date().getFullYear();

        return {
            date
        }
    }
})