import { defineComponent } from 'vue'

export default defineComponent({

  name: 'NavBar',
  setup () {
    return {

    }
  }
})
