

import { defineComponent } from 'vue'
import NavBar from '@/components/navbar/NavBar.vue'
import Footer from '@/components/footer/Footer.vue'

export default defineComponent({
  name: 'Home',
  components: { NavBar, Footer },
  setup () {
  //
  }
})
