import { resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, KeepAlive as _KeepAlive, withCtx as _withCtx, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NavBar = _resolveComponent("NavBar")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_Footer = _resolveComponent("Footer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_NavBar),
    _createVNode(_component_router_view, null, {
      default: _withCtx(({Component, route}) => [
        (_openBlock(), _createBlock(_KeepAlive, null, [
          (_openBlock(), _createBlock(_resolveDynamicComponent(Component), {
            key: route.name
          }))
        ], 1024))
      ]),
      _: 1
    }),
    _createVNode(_component_Footer)
  ], 64))
}