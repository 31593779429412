import { defineComponent } from 'vue'
import Tecnologias from '@/components/tech/Tecnologias.vue'

export default defineComponent({

  name: 'SoftSkills',
  components: {
    Tecnologias
  },
  setup () {
    return {

    }
  }
})
