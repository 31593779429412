import { defineComponent } from 'vue';
import Contact from '@/components/contact/Contact.vue';
import Education from '@/components/education/Education.vue';

export default defineComponent({

  name: 'Profile',
  components: {
    Contact,Education
  },
  setup () {
    return {
      
    }
  }
})
