

import { defineComponent } from 'vue';
import Profile from '@/components/profile/Profile.vue';
import Contact from '@/components/contact/Contact.vue';
import SoftSkills from '@/components/softskills/SoftSkills.vue';
import Experience from '@/components/experience/Experience.vue';
import Interests from '@/components/interests/Interests.vue';

export default defineComponent({
  name: 'Home',
  components: {
    Profile,Contact,SoftSkills,Experience,Interests
  },
  	data () {
		return {
			pdfLink : require('../assets/pdf/CV_LuisZepedaGonzalez.pdf')
		}
    },
});
